<template>
    <div class="wrap">
        <b-container class="navtab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('message.carshop')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </b-container>
        <!-- pc布局【购物车】 -->
        <b-container class="pc-shopcar">
            <div class="pc-shopwrap">
                <div class="pc-shop">
                    <b-row class="pc-titleshop">
                        <b-col class="pc-oths">{{$t('message.mycar')}}</b-col>
                        <b-col class="pc-empty">
                            <div class="pc-emptycar" @click="getCleanup"><span class="pc-empcar"></span>{{$t('message.emptycar')}}</div>
                        </b-col>
                    </b-row>
                    <div class="pc-shopgroup" v-show="datalist.length>0" v-for="(item,index) in datalist" :key="index">
                        <div class="pc-shopitem">
                            <div class="pc-shopimg"><img :src="item.goods_info.goods_img" alt=""/></div>
                            <div class="pc-shoptxt">
                                <div class="pc-title" @click="getShopInfo(item)">
                                    {{languageName=='cn'?item.goods_info.cn_name:''}}
                                    {{languageName=='en'?item.goods_info.en_name:''}}
                                    {{languageName=='it'?item.goods_info.it_name:''}}
                                </div>
                                <div class="pc-txt">{{item.spect_name}}</div>
                                <div class="pc-shopnum">{{$t('message.m')}}{{item.goods_info.moneyOne}}</div>
                            </div>
                            <div class="pc-shopbtn">
                                <div class="pc-tit_tab_btn">
                                    <span class="pc-leftbtn" v-show="!disclickableRemove" @click="getRemoveItem(item,index)"><span class="el-icon-minus"></span></span>
                                    <span class="pc-leftbtn" v-show="disclickableRemove"><span class="el-icon-minus"></span></span>
                                    <span class="pc-lefttxt">{{item.goods_no}}</span>
                                    <span class="pc-leftbtn" v-show="!disclickableAdd" @click="getAddItem(item,index)"><span class="el-icon-plus"></span></span>
                                    <span class="pc-leftbtn" v-show="disclickableAdd"><span class="el-icon-plus"></span></span>
                                </div>
                            </div>
                            <div class="pc-shopmoney">
                                <div class="money">{{$t('message.m')}}{{(item.shopCal).toFixed(2)}}</div>
                                <div class="shuifei" v-show="(item&&item.aamsCal)">AAMS: {{$t('message.m')}}{{(item.aamsCal>0)?item.aamsCal.toFixed(2):''}}</div>
                                <div class="shuifei" v-show="(item&&item.ivaCal)">IVA: {{$t('message.m')}}{{(item.ivaCal>0)?item.ivaCal.toFixed(2):''}}</div>
                            </div>
                            <div class="pc-shopdel" @click="getDeleteItem(item,index)">&times;</div>
                        </div>
                    </div>
                    <div class="pc-shopgroup" v-show="datalist.length==0">
                        <div class="pc-datano">{{$t('message.carnodata')}}~</div>
                    </div>
                </div>
                <div class="pc-all">
                    <div class="pc-title">{{$t('message.allshop')}}</div>
                    <div class="pc-money">{{$t('message.m')}}{{allMoney.toFixed(2)}}</div>
                    <!-- 烟油税：AAMS 增值税：IVA -->
                    <!-- <div class="pc-new-num" v-show="(allAams>0&&allIva>0)">AAMS: {{$t('message.m')}}{{(allAams).toFixed(2)}} IVA:{{$t('message.m')}}{{(allIva).toFixed(2)}}</div> -->
                    <div class="pc-new-num" v-show="(allAams>0&&allIva>0)">AAMS: {{$t('message.m')}}{{(allAams)}}</div>
                    <div class="pc-new-num" style="padding-bottom:20px;" v-show="(allAams>0&&allIva>0)">IVA:{{$t('message.m')}}{{allIva}}</div>
                    <div class="pc-btns pc-paybtn" @click="getPayNow">{{$t('message.letpay')}}</div>
                    <div class="pc-btns pc-addcar" @click="getShopList">{{$t('message.todopay')}}</div>
                </div>
            </div>
        </b-container>
        <!-- wap布局【购物车】 -->
        <b-container class="wap-shopcar">
            <b-row style="margin:0">
                <b-col class="wap-title">{{$t('message.mycar')}}</b-col>
                <b-col class="wap-empty-btn"><div class="wap-emptycar" @click="getCleanup"><span class="empcar"></span>{{$t('message.emptycar')}}</div></b-col>
            </b-row>
            <b-row style="margin:0">
                <div class="wap-shop-data" v-show="datalist.length>0" v-for="(item,index) in datalist" :key="index">
                    <div class="wap-shop-item">
                        <div class="wap-shop-img"><img :src="item.goods_info.goods_img" alt=""/></div>
                        <div class="wap-shop-txt">
                            <div class="title">
                                {{languageName=='cn'?item.goods_info.cn_name:''}}
                                {{languageName=='en'?item.goods_info.en_name:''}}
                                {{languageName=='it'?item.goods_info.it_name:''}}
                            </div>
                            <div class="txt">{{item.spect_name}}</div>
                            <div class="shopnum">{{$t('message.m')}}{{item.goods_info.moneyOne}}</div>
                        </div>
                        <div class="wap-shop-shbtn">
                            <div class="wap-del-shopbtn" @click="getDeleteItem(item,index)">&times;</div>
                            <div class="wap-tit_tab_btn">
                                <span class="wap-leftbtn" @click="getRemoveItem(item,index)"><span class="el-icon-minus"></span></span>
                                <span class="wap-lefttxt">{{item.goods_no}}</span>
                                <span class="wap-leftbtn" @click="getAddItem(item,index)"><span class="el-icon-plus"></span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wap-shopgroup" v-show="datalist.length==0">
                    <div class="wap-datano">{{$t('message.carnodata')}}~</div>
                </div>
            </b-row>
            <b-row class="wap-shop-btn">
                <b-row class="wap-shop-box" style="margin:0">
                    <b-col class="wap-shop-title">{{$t('message.allshop')}}</b-col>
                    <b-col class="wap-shop-money"><span class="money">{{$t('message.m')}}{{allMoney.toFixed(2)}}</span></b-col>
                </b-row>
                <!-- 烟油税：AAMS 增值税：IVA -->
                <!-- <b-row class="wap-shop-box" style="margin:0" v-show="(allAams>0&&allIva>0)">
                    <div class="wap-new-num">AAMS: {{$t('message.m')}}{{(allAams).toFixed(2)}} IVA:{{$t('message.m')}}{{(allIva).toFixed(2)}}</div>
                </b-row> -->
                <b-row class="wap-shop-box" style="margin:0" v-show="(allAams>0&&allIva>0)">
                    <div class="wap-new-num">AAMS: {{$t('message.m')}}{{(allAams)}} IVA:{{$t('message.m')}}{{(allIva)}}</div>
                </b-row>
                <b-row class="wap-shop-box" style="margin:0"><div class="btns paybtn" @click="getPayNow">{{$t('message.letpay')}}</div></b-row>
                <b-row class="wap-shop-box" style="margin:0;padding-bottom:20px;"><div class="btns addcar" @click="getShopList">{{$t('message.todopay')}}</div></b-row>
            </b-row>
        </b-container>
        <!-- 删除提示弹窗 -->
        <b-modal size="sm" centered v-model="dellaiog" hide-footer :title="$t('message.tiptitle')">
            <div class="d-block text-center">
                <p style="padding:30px 0;">{{deltype==0?$t('message.comfirdel'):$t('message.emptydel')}}</p>
            </div>
            <b-button  block @click="toggleModal">{{$t('message.firm')}}</b-button>
        </b-modal>
    </div>
</template>
<script>
import ElementUI from 'element-ui';
import {yooz_lang,getLocalItem,delLocalItem} from '@/untils/common';
export default {
    name:'shopcar',
    data(){
        return{
            token:'',
            login_user_info:{},
            languageName:'',
            languageObj:{},
            allMoney:0,//所有商品价格
            allAams:0,//所有商品的烟油税
            allIva:0,//所有商品的增值税
            datalistarr:[],
            disclickableAdd:false,
            disclickableRemove:false,
            datalist:[],
            dellaiog:false,
            deltype:0,//0是单个商品删除，1是清空购物车
            delitemobj:{},
            delobjindex:0,
            userPople:''//userPople:'company' 企业用户，数量10+
        }
    },
    methods: {
        
        // 获取购物车列表
        getCarListData(token){
            this.$http.api_order_getcartlistbytoken({
                token:token,
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.datalistarr = res.data;
                            // 当购物车数据大于零时，计算购物车内所有商品的总金额
                            if(this.datalistarr.length>0){
                                this.datalistarr.map((v)=>{
                                    // 接口返回价格是字符串类型，前端转化数字类型
                                    v.goods_info.cn_price = Number(v.goods_info.cn_price);
                                    v.goods_info.en_price = Number(v.goods_info.en_price);
                                    v.goods_info.it_price = Number(v.goods_info.it_price);
                                    // 商品规格
                                    this.$http.api_goods_shopgsinfobyid({
                                        id:v.goods_id
                                    }).then((respone)=>{
                                        if(respone){
                                            if(respone.code==1){
                                                respone.data.map((i,k)=>{
                                                    if(v.goods_spect_id==i.id){
                                                        v.spect_name = i.spect_name;
                                                        this.$set(this.datalistarr,v.spect_name,i.spect_name);
                                                    }
                                                });
                                            }else{
                                                ElementUI.Message({
                                                    message:respone.message,
                                                    type:'error',
                                                    center: true,
                                                    offset:10,
                                                });
                                                return false;
                                            }
                                        }
                                    });
                                    if(this.languageName=='cn'){
                                        if(v.goods_info.cn_price){
                                            // 折扣价
                                            if(v.goods_info.discounts!=0){
                                                v.goods_info.money = v.goods_info.cn_price*((v.goods_info.discounts)/100);//100-
                                                v.goods_info.moneyOne = v.goods_info.money.toFixed(2);
                                                v.shopCal = v.goods_info.cn_price*((v.goods_info.discounts)/100)*v.goods_no;//100-
                                                if(this.userPople=='company'){
                                                    v.aamsCal = v.goods_no*0.26;//烟油税
                                                    v.ivaCal = ((v.goods_info.cn_price*(v.goods_info.discounts/100))+0.26)*v.goods_no*0.22;//增值税
                                                }
                                            }else{
                                                // 非折扣价
                                                v.goods_info.moneyOne = v.goods_info.cn_price;
                                                v.shopCal = v.goods_info.cn_price*v.goods_no;
                                                if(this.userPople=='company'){
                                                    v.aamsCal = v.goods_no*0.26;//烟油税
                                                    v.ivaCal = (v.goods_info.cn_price+0.26)*v.goods_no*0.22;//增值税
                                                }
                                            }
                                        }else{
                                            v.goods_info.cn_price = 0;
                                            v.goods_info.moneyOne = v.goods_info.cn_price;
                                            v.shopCal = v.goods_info.cn_price*v.goods_no;
                                            if(this.userPople=='company'){
                                                v.aamsCal = v.goods_no*0.26;//烟油税
                                                v.ivaCal = (Number(v.goods_info.cn_price+0.26))*v.goods_no*0.22;//增值税
                                            }
                                        }
                                    }else if(this.languageName=='en'){
                                        if(v.goods_info.en_price){
                                            if(v.goods_info.discounts!=0){
                                                v.goods_info.money = v.goods_info.en_price*((v.goods_info.discounts)/100);//100-
                                                v.goods_info.moneyOne = v.goods_info.money.toFixed(2);
                                                v.shopCal = v.goods_info.en_price*((v.goods_info.discounts)/100)*v.goods_no;//100-
                                                if(this.userPople=='company'){
                                                    v.aamsCal = v.goods_no*0.26;//烟油税
                                                    v.ivaCal = ((v.goods_info.en_price*((v.goods_info.discounts)/100))+0.26)*v.goods_no*0.22;//增值税
                                                }
                                            }else{
                                                v.goods_info.moneyOne = v.goods_info.en_price;
                                                v.shopCal = v.goods_info.en_price*v.goods_no;
                                                if(this.userPople=='company'){
                                                    v.aamsCal = v.goods_no*0.26;//烟油税
                                                    v.ivaCal = (v.goods_info.en_price+0.26)*v.goods_no*0.22;//增值税
                                                }
                                            }
                                        }else{
                                            v.goods_info.en_price = 0;
                                            v.goods_info.moneyOne = v.goods_info.en_price;
                                            v.shopCal = v.goods_info.en_price*v.goods_no;
                                            if(this.userPople=='company'){
                                                v.aamsCal = v.goods_no*0.26;//烟油税
                                                v.ivaCal = (v.goods_info.en_price+0.26)*v.goods_no*0.22;//增值税
                                            }
                                        }
                                    }else if(this.languageName=='it'){
                                        if(v.goods_info.it_price){
                                            if(v.goods_info.discounts!=0){
                                                v.goods_info.money = v.goods_info.it_price*((v.goods_info.discounts)/100);//100-
                                                v.goods_info.moneyOne = v.goods_info.money.toFixed(2);
                                                v.shopCal = v.goods_info.it_price*((v.goods_info.discounts)/100)*v.goods_no;//100-
                                                if(this.userPople=='company'){
                                                    v.aamsCal = v.goods_no*0.26;//烟油税
                                                    v.ivaCal = ((v.goods_info.it_price*((v.goods_info.discounts)/100))+0.26)*v.goods_no*0.22;//增值税
                                                }
                                            }else{
                                                v.goods_info.moneyOne = v.goods_info.it_price;
                                                v.shopCal = v.goods_info.it_price*v.goods_no;
                                                if(this.userPople=='company'){
                                                    v.aamsCal = v.goods_no*0.26;//烟油税
                                                    v.ivaCal = (v.goods_info.it_price+0.26)*v.goods_no*0.22;//增值税
                                                }
                                            }
                                        }else{
                                            v.goods_info.it_price = 0;
                                            v.goods_info.moneyOne = v.goods_info.it_price;
                                            v.shopCal = v.goods_info.it_price*v.goods_no;
                                            if(this.userPople=='company'){
                                                v.aamsCal = v.goods_no*0.26;//烟油税
                                                v.ivaCal = (v.goods_info.it_price+0.26)*v.goods_no*0.22;//增值税
                                            }
                                        }
                                    }
                                });
                                // 购物车总金额
                                var money = 0;
                                var aams = 0;
                                var iva = 0;
                                for(var i=0;i<this.datalistarr.length;i++){
                                    money += this.datalistarr[i].shopCal;
                                    if(this.datalistarr[i].aamsCal){
                                        aams += this.datalistarr[i].aamsCal;
                                    }
                                    if(this.datalistarr[i].ivaCal){
                                        iva += this.datalistarr[i].ivaCal;
                                    }
                                }
                                this.allMoney = money+aams+iva;

                                this.allAams = aams.toFixed(2);
                                /*let allAamsarr1= aams.toString().split('.')[0];
                                let allAamsarr2=''
                                if(aams.toString().split('.').length>1){
                                    if(aams.toString().split('.')[1].length==1){
                                        allAamsarr2= aams.toString().split('.')[1]+'0';
                                    }else{
                                        allAamsarr2= aams.toString().split('.')[1].substring(0,2);
                                    }
                                    this.allAams = allAamsarr1+'.'+allAamsarr2;
                                }else{
                                    this.allAams = Number(allAamsarr1)+'.00';
                                }*/
                                this.allIva = iva.toFixed(2);
                                /*let allIvaarr1= iva.toString().split('.')[0];
                                let allIvaarr2=''
                                if(iva.toString().split('.').length>1){
                                    if(iva.toString().split('.')[1].length==1){
                                        allIvaarr2= iva.toString().split('.')[1]+'0';
                                    }else{
                                        allIvaarr2= iva.toString().split('.')[1].substring(0,2);
                                    }
                                    this.allIva = allIvaarr1+'.'+allIvaarr2;
                                }else{
                                    this.allIva = allIvaarr1+'.00';
                                }*/
                                
                                this.datalist = this.datalistarr;
                            }
                        }
                    }else if(res.code==-1){
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        // ElementUI.Message({
                        //     message:res.message,
                        //     type:'error',
                        //     center: true,
                        //     offset:10,
                        // });
                        // return false;
                    }
                }
            });
        },
        // 单个商品数量加1
        getAddItem(item,index){
            this.disclickableAdd = true;
            this.datalist.map((v,k)=>{
                if(v.id==item.id){
                    v.goods_no = parseInt(v.goods_no);
                    if(this.userPople=='company'){
                        v.goods_no = v.goods_no+10
                    }else{
                        v.goods_no++
                    }
                }
            });
            if(this.token){
                let params = {
                    token:this.token,//（用户token）、
                    goods_id:item.goods_id,//（商品id）、
                    goods_no:item.goods_no,//（商品数量）、
                    is_delete:0,//（是否删除 1是 0否）、
                    is_add:1,//（是否添加 1是 0否）、
                    is_edit:1,//（是否 编辑 1是 0否）
                    goods_spect_id:item.goods_spect_id,//(商品规格id)
                }
                this.$http.api_order_editcart(params).then((res)=>{
                    if(res){
                        if(res.code==1){
                            this.datalist.map((v,k)=>{
                                if(index==k&&(v.goods_no)>0){
                                    
                                    // 接口返回价格是字符串类型，前端转化数字类型
                                    v.goods_info.cn_price = Number(v.goods_info.cn_price);
                                    v.goods_info.en_price = Number(v.goods_info.en_price);
                                    v.goods_info.it_price = Number(v.goods_info.it_price);
                                    if(this.languageName=='cn'){
                                        if(v.goods_info.cn_price){
                                            if(v.goods_info.discounts!=0){
                                                v.shopCal = (v.goods_info.cn_price)*((v.goods_info.discounts)/100)*(v.goods_no);//100-
                                                if(this.userPople=='company'){
                                                    v.aamsCal = v.goods_no*0.26;//烟油税
                                                    v.ivaCal = ((v.goods_info.cn_price*((v.goods_info.discounts)/100))+0.26)*v.goods_no*0.22;//增值税
                                                }
                                            }else{
                                                v.shopCal = (v.goods_info.cn_price)*(v.goods_no);
                                                if(this.userPople=='company'){
                                                    v.aamsCal = v.goods_no*0.26;//烟油税
                                                    v.ivaCal = (v.goods_info.cn_price+0.26)*v.goods_no*0.22;//增值税
                                                }
                                            }
                                        }else{
                                            v.goods_info.cn_price = 0;
                                            v.shopCal = (v.goods_info.cn_price)*(v.goods_no);
                                            if(this.userPople=='company'){
                                                v.aamsCal = v.goods_no*0.26;//烟油税
                                                v.ivaCal = (v.goods_info.cn_price+0.26)*v.goods_no*0.22;//增值税
                                            }
                                        }
                                    }else if(this.languageName=='en'){
                                        if(v.goods_info.en_price){
                                            if(v.goods_info.discounts!=0){
                                                v.shopCal = (v.goods_info.en_price)*((v.goods_info.discounts)/100)*(v.goods_no);//100-
                                                if(this.userPople=='company'){
                                                    v.aamsCal = v.goods_no*0.26;//烟油税
                                                    v.ivaCal = ((v.goods_info.en_price*((v.goods_info.discounts)/100))+0.26)*v.goods_no*0.22;//增值税
                                                }
                                            }else{
                                                v.shopCal = (v.goods_info.en_price)*(v.goods_no);
                                                if(this.userPople=='company'){
                                                    v.aamsCal = v.goods_no*0.26;//烟油税
                                                    v.ivaCal = (v.goods_info.en_price+0.26)*v.goods_no*0.22;//增值税
                                                }
                                            }
                                        }else{
                                            v.goods_info.en_price = 0;
                                            v.shopCal = (v.goods_info.en_price)*(v.goods_no);
                                            if(this.userPople=='company'){
                                                v.aamsCal = v.goods_no*0.26;//烟油税
                                                v.ivaCal = (v.goods_info.en_price+0.26)*v.goods_no*0.22;//增值税
                                            }
                                        }
                                    }else if(this.languageName=='it'){
                                        if(v.goods_info.it_price){
                                            if(v.goods_info.discounts!=0){
                                                v.shopCal = (v.goods_info.it_price)*((v.goods_info.discounts)/100)*(v.goods_no);//100-
                                                if(this.userPople=='company'){
                                                    v.aamsCal = v.goods_no*0.26;//烟油税
                                                    v.ivaCal = ((v.goods_info.it_price*((v.goods_info.discounts)/100))+0.26)*v.goods_no*0.22;//增值税
                                                }
                                            }else{
                                                v.shopCal = (v.goods_info.it_price)*(v.goods_no);
                                                if(this.userPople=='company'){
                                                    v.aamsCal = v.goods_no*0.26;//烟油税
                                                    v.ivaCal = (v.goods_info.it_price+0.26)*v.goods_no*0.22;//增值税
                                                }
                                            }
                                        }else{
                                            v.goods_info.it_price = 0;
                                            v.shopCal = (v.goods_info.it_price)*(v.goods_no);
                                            if(this.userPople=='company'){
                                                v.aamsCal = v.goods_no*0.26;//烟油税
                                                v.ivaCal = (v.goods_info.it_price+0.26)*v.goods_no*0.22;//增值税
                                            }
                                        }
                                    }
                                }
                            });
                            var money = 0;
                            var aams = 0;
                            var iva = 0;
                            for(var i=0;i<this.datalist.length;i++){
                                money += this.datalist[i].shopCal;
                                if(this.datalist[i].aamsCal){
                                    aams += this.datalist[i].aamsCal;
                                }
                                if(this.datalist[i].ivaCal){
                                    iva += this.datalist[i].ivaCal;
                                }
                            }
                            // 总价格
                            this.allMoney = money+aams+iva;
                            this.allAams = aams.toFixed(2);
                            /*let allAamsarr1= aams.toString().split('.')[0];
                            let allAamsarr2=''
                            if(aams.toString().split('.').length>1){
                                if(aams.toString().split('.')[1].length==1){
                                    allAamsarr2= aams.toString().split('.')[1]+'0';
                                }else{
                                    allAamsarr2= aams.toString().split('.')[1].substring(0,2);
                                }
                                this.allAams = allAamsarr1+'.'+allAamsarr2;
                            }else{
                                this.allAams = Number(allAamsarr1)+'.00';
                            }*/
                            this.allIva = iva.toFixed(2);
                            /*
                            let allIvaarr1= iva.toString().split('.')[0];
                            let allIvaarr2=''
                            if(iva.toString().split('.').length>1){
                                if(iva.toString().split('.')[1].length==1){
                                    allIvaarr2= iva.toString().split('.')[1]+'0';
                                }else{
                                    allIvaarr2= iva.toString().split('.')[1].substring(0,2);
                                }
                                this.allIva = allIvaarr1+'.'+allIvaarr2;
                            }else{
                                this.allIva = Number(allIvaarr1)+'.00';
                            }*/
                            this.disclickableAdd = false;
                        }else if(res.code==-1){
                            this.$store.commit('setLoginNameStatus', {});
                            delLocalItem('login_user_info');
                            this.$root.Bus.$emit('userInfoClick', {});
                            this.$store.commit('setLoginStatus',true);
                            this.$router.push({path:'/'});
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }
        },
        // 单个商品数量减1
        getRemoveItem(item,index){
            this.disclickableRemove = true;
            if(this.datalist[index].goods_no<=1){
                ElementUI.Message({
                    message:this.languageObj.removenumber,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }
            if(this.datalist[index].goods_no<=0){
                this.datalist[index].goods_no = 0;
            }else {
                if(this.userPople=='company'){
                    if(this.datalist[index].goods_no<=10){
                        ElementUI.Message({
                            message:this.languageObj.removenumber,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }else{
                        this.datalist[index].goods_no = this.datalist[index].goods_no-10
                    }
                }else{
                    this.datalist[index].goods_no--;
                }
                
            }
            if(this.token&&item){
                let params = {
                    token:this.token,//（用户token）、
                    goods_id:item.goods_id,//（商品id）、
                    goods_no:this.datalist[index].goods_no,//（商品数量）、
                    is_delete:0,//（是否删除 1是 0否）、
                    is_add:0,//（是否添加 1是 0否）、
                    is_edit:1,//（是否 编辑 1是 0否）
                    goods_spect_id:item.goods_spect_id,//(商品规格id)
                }
                this.$http.api_order_editcart(params).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                this.datalist.map((v,k)=>{
                                    if(index==k&&v.goods_no>=0){
                                        
                                        // 接口返回价格是字符串类型，前端转化数字类型
                                        v.goods_info.cn_price = Number(v.goods_info.cn_price);
                                        v.goods_info.en_price = Number(v.goods_info.en_price);
                                        v.goods_info.it_price = Number(v.goods_info.it_price);
                                        if(this.languageName=='cn'){
                                            if(v.goods_info.cn_price){
                                                if(v.goods_info.discounts!=0){
                                                    v.shopCal = (v.goods_info.cn_price)*((v.goods_info.discounts)/100)*(v.goods_no);//100-
                                                    if(this.userPople=='company'){
                                                        v.aamsCal = v.goods_no*0.26;//烟油税
                                                        v.ivaCal = ((v.goods_info.cn_price*((v.goods_info.discounts)/100))+0.26)*v.goods_no*0.22;//增值税
                                                    }
                                                }else{
                                                    v.shopCal = (v.goods_info.cn_price)*(v.goods_no);
                                                    if(this.userPople=='company'){
                                                        v.aamsCal = v.goods_no*0.26;//烟油税
                                                        v.ivaCal = (v.goods_info.cn_price+0.26)*v.goods_no*0.22;//增值税
                                                    }
                                                }
                                            }else{
                                                v.goods_info.cn_price = 0;
                                                v.shopCal = (v.goods_info.cn_price)*(v.goods_no);
                                                if(this.userPople=='company'){
                                                    v.aamsCal = v.goods_no*0.26;//烟油税
                                                    v.ivaCal = (v.goods_info.cn_price+0.26)*v.goods_no*0.22;//增值税
                                                }
                                            }
                                        }else if(this.languageName=='en'){
                                            if(v.goods_info.en_price){
                                                if(v.goods_info.discounts!=0){
                                                    v.shopCal = (v.goods_info.en_price)*((v.goods_info.discounts)/100)*(v.goods_no);//100-
                                                    if(this.userPople=='company'){
                                                        v.aamsCal = v.goods_no*0.26;//烟油税
                                                        v.ivaCal = ((v.goods_info.en_price*((v.goods_info.discounts)/100))+0.26)*v.goods_no*0.22;//增值税
                                                    }
                                                }else{
                                                    v.shopCal = (v.goods_info.en_price)*(v.goods_no);
                                                    if(this.userPople=='company'){
                                                        v.aamsCal = v.goods_no*0.26;//烟油税
                                                        v.ivaCal = (v.goods_info.en_price+0.26)*v.goods_no*0.22;//增值税
                                                    }
                                                }
                                            }else{
                                                v.goods_info.en_price = 0;
                                                v.shopCal = (v.goods_info.en_price)*(v.goods_no);
                                                if(this.userPople=='company'){
                                                    v.aamsCal = v.goods_no*0.26;//烟油税
                                                    v.ivaCal = (v.goods_info.en_price+0.26)*v.goods_no*0.22;//增值税
                                                }
                                            }
                                        }else if(this.languageName=='it'){
                                            if(v.goods_info.it_price){
                                                if(v.goods_info.discounts!=0){
                                                    v.shopCal = (v.goods_info.it_price)*((v.goods_info.discounts)/100)*(v.goods_no);//100-
                                                    if(this.userPople=='company'){
                                                        v.aamsCal = v.goods_no*0.26;//烟油税
                                                        v.ivaCal = ((v.goods_info.it_price*((v.goods_info.discounts)/100))+0.26)*v.goods_no*0.22;//增值税
                                                    }
                                                }else{
                                                    v.shopCal = (v.goods_info.it_price)*(v.goods_no);
                                                    if(this.userPople=='company'){
                                                        v.aamsCal = v.goods_no*0.26;//烟油税
                                                        v.ivaCal = (v.goods_info.it_price+0.26)*v.goods_no*0.22;//增值税
                                                    }
                                                }
                                            }else{
                                                v.goods_info.it_price = 0;
                                                v.shopCal = (v.goods_info.it_price)*(v.goods_no);
                                                if(this.userPople=='company'){
                                                    v.aamsCal = v.goods_no*0.26;//烟油税
                                                    v.ivaCal = (v.goods_info.it_price+0.26)*v.goods_no*0.22;//增值税
                                                }
                                            }
                                        }
                                    }
                                });
                                var money = 0;
                                var aams = 0;
                                var iva = 0;
                                for(var i=0;i<this.datalist.length;i++){
                                    money += this.datalist[i].shopCal;
                                    if(this.datalist[i].aamsCal){
                                        aams += this.datalist[i].aamsCal;
                                    }
                                    if(this.datalist[i].ivaCal){
                                        iva += this.datalist[i].ivaCal;
                                    }
                                }
                                this.allMoney = money+aams+iva;
                                this.allAams = aams.toFixed(2);;
                                /*let allAamsarr1= aams.toString().split('.')[0];
                                let allAamsarr2=''
                                if(aams.toString().split('.').length>1){
                                    if(aams.toString().split('.')[1].length==1){
                                        allAamsarr2= aams.toString().split('.')[1]+'0';
                                    }else{
                                        allAamsarr2= aams.toString().split('.')[1].substring(0,2);
                                    }
                                    this.allAams = allAamsarr1+'.'+allAamsarr2;
                                }else{
                                    this.allAams = Number(allAamsarr1)+'.00';
                                }*/
                                
                                this.allIva = iva.toFixed(2);;
                                /*let allIvaarr1= iva.toString().split('.')[0];
                                let allIvaarr2=''
                                if(iva.toString().split('.').length>1){
                                    if(iva.toString().split('.')[1].length==1){
                                        allIvaarr2= iva.toString().split('.')[1]+'0';
                                    }else{
                                        allIvaarr2= iva.toString().split('.')[1].substring(0,2);
                                    }
                                    this.allIva = allIvaarr1+'.'+allIvaarr2;
                                }else{
                                    this.allIva = Number(allIvaarr1)+'.00';
                                }*/
                                this.disclickableRemove = false;
                            }
                        }else if(res.code==-1){
                            this.$store.commit('setLoginNameStatus', {});
                            delLocalItem('login_user_info');
                            this.$root.Bus.$emit('userInfoClick', {});
                            this.$store.commit('setLoginStatus',true);
                            this.$router.push({path:'/'});
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }
        },
        // 单个商品的删除
        getDeleteItem(item,index){
            if(this.token&&item){
                this.dellaiog = !this.dellaiog;
                this.deltype = 0;
                this.delitemobj = item;
                this.delobjindex = index;
                // let params = item;
                // params.index = index;
                // params.deltype = 'cartone';
                // this.$store.commit('setDelStatus',true);
                // this.$store.commit('setDelItemStatus',params);
            }else{
                ElementUI.Message({
                    message:this.languageObj.logintip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }
        },
        // 清空购物车
        getCleanup(){
            if(this.token){
                if(this.datalist.length>0){
                    this.dellaiog = !this.dellaiog;
                    this.deltype = 1;
                    // let params = {};
                    // params.deltype = 'cartclear';
                    // this.$store.commit('setDelStatus',true);
                    // this.$store.commit('setDelItemStatus',params);
                }else{
                    ElementUI.Message({
                        message:this.languageObj.nolist,
                        type:'warning',
                        center: true,
                        offset:10,
                    });
                    return false;
                }
            }else{
                ElementUI.Message({
                    message:this.languageObj.logintip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }
        },
        hideModal() {
            this.dellaiog = !this.dellaiog;
        },
        toggleModal() {
            // 清空购物车
            if(this.deltype==1){
                this.$http.api_order_cleancart({
                    token:this.token,
                }).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                this.datalist = [];
                                this.allMoney = 0;
                                this.allAams = 0;
                                this.allIva = 0;
                                this.dellaiog = false;
                            }
                        }else if(res.code==-1){
                            this.$store.commit('setLoginNameStatus', {});
                            delLocalItem('login_user_info');
                            this.$root.Bus.$emit('userInfoClick', {});
                            this.$store.commit('setLoginStatus',true);
                            this.$router.push({path:'/'});
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }else{
                // 购物车单个删除商品
                let params = {
                    token:this.token,//（用户token）、
                    goods_id:this.delitemobj.goods_id,//（商品id）、
                    goods_no:this.delitemobj.goods_no,//（商品数量）、
                    is_delete:1,//（是否删除 1是 0否）、
                    is_add:0,//（是否添加 1是 0否）、
                    is_edit:1,//（是否 编辑 1是 0否）
                    goods_spect_id:this.delitemobj.goods_spect_id//(商品规格id)
                }
                this.$http.api_order_editcart(params).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                this.dellaiog = false;
                                this.datalist.forEach((v,k)=>{
                                    if(this.delobjindex == k){
                                        this.datalist.splice(k,1);
                                    }
                                });
                                if(this.datalist.length>0){
                                    this.datalist.map((v)=>{
                                        
                                        // 接口返回价格是字符串类型，前端转化数字类型
                                        v.goods_info.cn_price = Number(v.goods_info.cn_price);
                                        v.goods_info.en_price = Number(v.goods_info.en_price);
                                        v.goods_info.it_price = Number(v.goods_info.it_price);
                                        if(this.languageName=='cn'){
                                            if(v.goods_info.cn_price){
                                                if(v.goods_info.discounts!=0){
                                                    v.shopCal = (v.goods_info.cn_price)*((v.goods_info.discounts)/100)*(v.goods_no);//100-
                                                    if(this.userPople=='company'){
                                                        v.aamsCal = v.goods_no*0.26;//烟油税
                                                        v.ivaCal = ((v.goods_info.it_price*((v.goods_info.discounts)/100))+0.26)*v.goods_no*0.22;//增值税
                                                    }
                                                }else{
                                                    v.shopCal = (v.goods_info.cn_price)*(v.goods_no);
                                                    if(this.userPople=='company'){
                                                        v.aamsCal = v.goods_no*0.26;//烟油税
                                                        v.ivaCal = (v.goods_info.cn_price+0.26)*v.goods_no*0.22;//增值税
                                                    }
                                                }
                                            }else{
                                                v.goods_info.cn_price = 0;
                                                v.shopCal = (v.goods_info.cn_price)*(v.goods_no);
                                                if(this.userPople=='company'){
                                                    v.aamsCal = v.goods_no*0.26;//烟油税
                                                    v.ivaCal = (v.goods_info.cn_price+0.26)*v.goods_no*0.22;//增值税
                                                }
                                            }
                                        }else if(this.languageName=='en'){
                                            if(v.goods_info.en_price){
                                                if(v.goods_info.discounts!=0){
                                                    v.shopCal = (v.goods_info.en_price)*((v.goods_info.discounts)/100)*(v.goods_no);//100-
                                                    if(this.userPople=='company'){
                                                        v.aamsCal = v.goods_no*0.26;//烟油税
                                                        v.ivaCal = ((v.goods_info.en_price*((v.goods_info.discounts)/100))+0.26)*v.goods_no*0.22;//增值税
                                                    }
                                                }else{
                                                    v.shopCal = (v.goods_info.en_price)*(v.goods_no);
                                                    if(this.userPople=='company'){
                                                        v.aamsCal = v.goods_no*0.26;//烟油税
                                                        v.ivaCal = (v.goods_info.en_price+0.26)*v.goods_no*0.22;//增值税
                                                    }
                                                }
                                            }else{
                                                v.goods_info.en_price = 0;
                                                v.shopCal = (v.goods_info.en_price)*(v.goods_no);
                                                if(this.userPople=='company'){
                                                    v.aamsCal = v.goods_no*0.26;//烟油税
                                                    v.ivaCal = (v.goods_info.en_price+0.26)*v.goods_no*0.22;//增值税
                                                }
                                            }
                                        }else if(this.languageName=='it'){
                                            if(v.goods_info.it_price){
                                                if(v.goods_info.discounts!=0){
                                                    v.shopCal = (v.goods_info.it_price)*((v.goods_info.discounts)/100)*(v.goods_no);//100-
                                                    if(this.userPople=='company'){
                                                        v.aamsCal = v.goods_no*0.26;//烟油税
                                                        v.ivaCal = ((v.goods_info.it_price*((v.goods_info.discounts)/100))+0.26)*v.goods_no*0.22;//增值税
                                                    }
                                                }else{
                                                    v.shopCal = (v.goods_info.it_price)*(v.goods_no);
                                                    if(this.userPople=='company'){
                                                        v.aamsCal = v.goods_no*0.26;//烟油税
                                                        v.ivaCal = (v.goods_info.it_price+0.26)*v.goods_no*0.22;//增值税
                                                    }
                                                }
                                            }else{
                                                v.goods_info.it_price = 0;
                                                v.shopCal = (v.goods_info.it_price)*(v.goods_no);
                                                if(this.userPople=='company'){
                                                    v.aamsCal = v.goods_no*0.26;//烟油税
                                                    v.ivaCal = (v.goods_info.it_price+0.26)*v.goods_no*0.22;//增值税
                                                }
                                            }
                                        }
                                    });
                                    var money = 0;
                                    var aams = 0;
                                    var iva = 0;
                                    for(var i=0;i<this.datalist.length;i++){
                                        money += this.datalist[i].shopCal;
                                        if(this.datalist[i].aamsCal){
                                            aams += this.datalist[i].aamsCal;
                                        }
                                        if(this.datalist[i].ivaCal){
                                            iva += this.datalist[i].ivaCal;
                                        }
                                    }
                                    this.allMoney = money+aams+iva;
                                    this.allAams = aams.toFixed(2);
                                    /*let allAamsarr1= aams.toString().split('.')[0];
                                    let allAamsarr2=''
                                    if(aams.toString().split('.').length>1){
                                        if(aams.toString().split('.')[1].length==1){
                                            allAamsarr2= aams.toString().split('.')[1]+'0';
                                        }else{
                                            allAamsarr2= aams.toString().split('.')[1].substring(0,2);
                                        }
                                        this.allAams = allAamsarr1+'.'+allAamsarr2;
                                    }else{
                                        this.allAams = Number(allAamsarr1)+'.00';
                                    }*/
                                    
                                    this.allIva = iva.toFixed(2);
                                    /*let allIvaarr1= iva.toString().split('.')[0];
                                    let allIvaarr2=''
                                    if(iva.toString().split('.').length>1){
                                        if(iva.toString().split('.')[1].length==1){
                                            allIvaarr2= iva.toString().split('.')[1]+'0';
                                        }else{
                                            allIvaarr2= iva.toString().split('.')[1].substring(0,2);
                                        }
                                        this.allIva = allIvaarr1+'.'+allIvaarr2;
                                    }else{
                                        this.allIva = Number(allIvaarr1)+'.00';
                                    }*/
                                }else{
                                    this.allMoney = 0;
                                    this.allAams = 0;
                                    this.allIva = 0;
                                }
                                ElementUI.Message({
                                    message:this.languageObj.delsuccess,
                                    type:'success',
                                    center: true,
                                    offset:10,
                                });
                            }
                        }else if(res.code==-1){
                            this.$store.commit('setLoginNameStatus', {});
                            delLocalItem('login_user_info');
                            this.$root.Bus.$emit('userInfoClick', {});
                            this.$store.commit('setLoginStatus',true);
                            this.$router.push({path:'/'});
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }
            
        },
        // 立即购买
        getPayNow(){
            if(this.token){
                if(this.datalist.length>0){
                    if(this.login_user_info.is_company==1){
                        // 企业用户
                        if(!this.login_user_info.cinfo.company_name){
                            this.$router.push({path:'/uinfo',query:{type:3}});
                        }else{
                            let arrCarList = [];
                            // 当购物车数据大于零时，计算购物车内所有商品的总金额
                            this.datalist.map((v)=>{
                                arrCarList.push(v.id);
                            });
                            // 创建订单
                            this.$http.api_order_createorder({
                                "token":this.token,
                                "cart_id":arrCarList,
                                "country":this.languageName
                            }).then((respone)=>{
                                if(respone){
                                    if(respone.code==1){
                                        if(respone.data&&respone.data.orderno){
                                            this.$router.push({path:'/shopfrom',query:{orderno:respone.data.orderno} })
                                        }
                                    }else if(respone.code==-1){
                                        this.$store.commit('setLoginNameStatus', {});
                                        delLocalItem('login_user_info');
                                        this.$root.Bus.$emit('userInfoClick', {});
                                        this.$store.commit('setLoginStatus',true);
                                        this.$router.push({path:'/'});
                                    }else{
                                        ElementUI.Message({
                                            message:respone.message,
                                            type:'error',
                                            center: true,
                                            offset:10,
                                        });
                                        return false;
                                    }
                                }
                            });
                        }
                    }else{
                        // 普通用户
                        if(!this.login_user_info.family_name){
                            this.$router.push({path:'/uinfo',query:{type:1}});
                        }else{
                            let arrCarList = [];
                            // 当购物车数据大于零时，计算购物车内所有商品的总金额
                            this.datalist.map((v)=>{
                                arrCarList.push(v.id);
                            });
                            // 创建订单
                            this.$http.api_order_createorder({
                                "token":this.token,
                                "cart_id":arrCarList,
                                "country":this.languageName
                            }).then((respone)=>{
                                if(respone){
                                    if(respone.code==1){
                                        if(respone.data&&respone.data.orderno){
                                            this.$router.push({path:'/shopfrom',query:{orderno:respone.data.orderno} })
                                        }
                                    }else if(respone.code==-1){
                                        this.$store.commit('setLoginNameStatus', {});
                                        delLocalItem('login_user_info');
                                        this.$root.Bus.$emit('userInfoClick', {});
                                        this.$store.commit('setLoginStatus',true);
                                        this.$router.push({path:'/'});
                                    }else{
                                        ElementUI.Message({
                                            message:respone.message,
                                            type:'error',
                                            center: true,
                                            offset:10,
                                        });
                                        return false;
                                    }
                                }
                            });
                        }
                    }
                    // if(!this.login_user_info.family_name){
                    //     this.$router.push({path:'/uinfo',query:{type:1}});
                    // }
                }else{
                    ElementUI.Message({
                        message:this.languageObj.cartempty,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }
                
            }else{
                this.$store.commit('setLoginStatus',true);
            }
        },
        // 继续购物
        getShopList(){
            this.$router.push({path:'/'});
            // this.$router.go(-1);//返回上一页
            // this.$router.push({path:'/list',query:{type:0}});
        },
        getShopInfo(item){
            if(item.goods_id){
                this.$router.push({path:'/info',query:{shopid:item.goods_id}});
            }
        }
    },
    created(){
        
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        if(this.languageName=='cn'){
            this.languageObj = {
                "logintip":"请先登录",
                "nolist":"暂无数据",
                "removenumber":"数量不能再少了",
                "cartempty":"购物车为空",
                "delsuccess":"删除成功",
            }
        }else if(this.languageName=='en'){
            this.languageObj = {
                "logintip":"please log in first",
                "nolist":"No data",
                "removenumber":"The quantity cannot be less",
                "cartempty":"Shopping cart is empty",
                "delsuccess":"Successfully deleted",
            }
        }else if(this.languageName=='it'){
            this.languageObj = {
                "logintip":"fai prima il log in",
                "nolist":"Nessun dato",
                "removenumber":"La quantità non può essere inferiore",
                "cartempty":"Il carrello è vuoto",
                "delsuccess":"Eliminato con successo",
            }
        }
        let login_user_info = this.$store.state.data.loginNameStatus.family_name?this.$store.state.data.loginNameStatus:(getLocalItem('login_user_info')?getLocalItem('login_user_info'):null);
        if(login_user_info){
            this.login_user_info = login_user_info;
            // 再次判断是否是企业用户(是否企业身份 1是 0否)
            if(login_user_info.is_company==1){
                this.userPople='company';
                this.shopNumber=10
            }
        }
        let token = getLocalItem('login_user_info')&&getLocalItem('login_user_info').token?getLocalItem('login_user_info').token:'';
        if(token){
            this.token = token;
            this.getCarListData(token);// 获取购物车列表
            
        }else{
            // ElementUI.Message({
            //     message:this.languageObj.logintip,
            //     type:'error',
            //     center: true,
            //     offset:10,
            // });
            // this.$store.commit('setLoginStatus',true);
            this.$router.push({path:'/'});
            return false;
        }
    }
}
</script>
<style lang="scss" scoped>


.navtab{
    padding:20px 10px 10px;
    
}
.pc-shopcar{
    padding:20px 0;
    .pc-shopwrap{
        width: 100%;
        display: flex;
        -webkit-box-orient: horizontal;
        .pc-shop{
            width: 60%;
            .pc-titleshop{
                margin-bottom: 20px;
            }
            .pc-oths{
                font-size:20px;
            }
            .pc-empty{
                text-align: right;
                padding-right:60px;
                .pc-emptycar{
                    display: inline-block;
                    cursor: pointer;
                    border: 1px solid #1F73B7;
                    padding:4px 10px;
                    
                    color: #1F73B7;
                    .pc-empcar{
                        display: inline-block;
                        width: 20px;
                        height:20px;
                        background: url(~@/assets/images/icon_delete.png) no-repeat left top;
                        background-size: 100% 100%;
                        vertical-align: middle;
                        margin-top: -4px;
                        margin-right: 8px;
                    }
                }
            }
            .pc-shopgroup{
                padding:10px 30px 10px 0;
                .pc-datano{
                    text-align: center;
                    color: #95959e;
                }
                .pc-shopitem{
                    width: 100%;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    padding:10px 0 20px;
                    border-bottom: 1px solid #DDE0ED;
                    .pc-shopimg{
                        width: 20%;
                        height:100px;
                        img{
                            width: 75%;
                            height:100px;
                        }
                    }
                    .pc-shoptxt{
                        width: 30%;
                        padding-left:10px;
                        padding-right:10px;
                        .pc-title{
                            cursor: pointer;
                            
                            padding:5px 0;

                        }
                        .pc-txt{
                            color: #95959E;
                        }
                        .pc-shopnum{
                            
                            margin-top: 15px;
                        }
                    }
                    .pc-shopbtn{
                        width: 22%;
                        padding-top: 22px;
                        .pc-tit_tab_btn{
                            display: inline-block;
                            border:1px solid #95959e;
                            border-radius: 8px;
                            height:35px;
                            line-height:35px;
                            margin: 10px 0;
                            .pc-leftbtn{
                                display: inline-block;
                                cursor: pointer;
                                height:35px;
                                padding:0 10px;
                                color: #a5a5ad;
                            }
                            .pc-lefttxt{
                                display: inline-block;
                                height:34px;
                                width:34px;
                                text-align: center;
                                // padding:0 16px;
                                border-left:1px solid #95959e;
                                border-right:1px solid #95959e;
                            }
                        }
                    }
                    .pc-shopmoney{
                        width: 23%;
                        .money{
                            color: #bf0000;
                            font-size:20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                        }
                        .shuifei{
                            color: #999;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                        }
                    }
                    .pc-shopdel{
                        width: 5%;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size:28px;
                    }
                }
            }
        }
        .pc-all{
            width: 40%;
            background: #f4f7fe;
            text-align: center;
            padding:20px 0 40px;
            .pc-title{
                font-size:30px;
                padding:20px 0;
            }
            .pc-money{
                color: #bf0000;
                font-size:30px;
                padding:20px 0 0;
            }
            .pc-new-num{
                color: #666;
                font-size:18px;
            }
            .pc-btns{
                height:44px;
                cursor: pointer;
                text-align: center;
                line-height:44px;
                width: 50%;
                border:1px solid #222;
                margin: 10px auto 20px;
            }
            .pc-addcar{
                color: #fff;
                background: #222;
            }
            .pc-paybtn{
                background: #fff;
                color: #222;
            }
        }
    }
    
    
}
/deep/.el-breadcrumb__inner.is-link{
    color: #1f73b7;
    font-weight: normal;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
    color: #95959e;
}
@media screen and (min-width: 1200px) {
    .wrap{
        .wap-shopcar{display: none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        .wap-shopcar{display: none;}
        .pc-shopcar .pc-shopwrap .pc-shop .pc-shopgroup .pc-shopitem .pc-shopbtn .pc-tit_tab_btn .pc-leftbtn{
            padding: 0 7px;
        }
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        .pc-shopcar{display: none;}
        .wap-shopcar{
            padding:10px;
            .wap-title{
                font-size:18px;
                padding:0;
            }
            .wap-empty-btn{
                text-align: right;
                padding:0 10px;
                .wap-emptycar{
                    display: inline-block;
                    cursor: pointer;
                    border: 1px solid #1F73B7;
                    padding:3px 10px;
                    
                    color: #1F73B7;
                    .empcar{
                        display: inline-block;
                        width: 16px;
                        height:16px;
                        background: url(~@/assets/images/icon_delete.png) no-repeat left top;
                        background-size: 100% 100%;
                        vertical-align: middle;
                        margin-top: -4px;
                        margin-right: 4px;
                    }
                }
            }
            .wap-shop-data{
                width: 100%;
                display: flex;
                -webkit-box-orient: vertical;
                padding:10px ;
                margin: 0;
                .wap-shop-item{
                    width: 100%;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .wap-shop-img{
                        width:30%;
                        padding:0 10px 0 0;
                        img{width:100%}
                    }
                    .wap-shop-txt{
                        width:40%;
                        .title{
                            
                            padding:5px 0;
                        }
                        .txt{
                            
                            padding:0px 0 5px;
                            color: #95959e;
                        }
                        .shopnum{
                            
                            margin-top: 15px;
                        }
                    }
                    .wap-shop-shbtn{
                        width:30%;
                        text-align: right;
                        .wap-del-shopbtn{
                            text-align: right;
                            font-size:26px;
                            padding:10px 0;
                        }
                        .wap-tit_tab_btn{
                            display: inline-block;
                            border:1px solid #95959e;
                            border-radius: 8px;
                            height:32px;
                            width: 100%;
                            
                            .wap-leftbtn{
                                display: inline-block;
                                cursor: pointer;
                                height:30px;
                                line-height:30px;
                                padding:0 8px;
                                width:30%;
                                color: #a5a5ad;
                                .el-icon-minus{
                                    
                                }
                                .el-icon-plus{
                                    
                                }
                            }
                            .wap-lefttxt{
                                display: inline-block;
                                height:30px;
                                line-height:30px;
                                text-align: center;
                                width:40%;
                                vertical-align:top;
                                border-left:1px solid #95959e;
                                border-right:1px solid #95959e;
                            }
                        }
                        
                    }
                }
                
            }
            .wap-shop-btn{
                background: #fff;
                padding:0px 0px 0px;
                margin: 0;
                .wap-shop-box{
                    width: 100%;
                    background: #f4f7fe;
                    padding:10px 15px 1px;
                    .wap-shop-title{
                        padding:0;
                        
                    }
                    .wap-shop-money{
                        text-align: right;
                        padding:0;
                        font-weight: bold;
                    }
                    .money{
                        color: #bf0000;
                    }
                    .wap-new-num{
                        color: #666;
                    }
                    .btns{
                        height:42px;
                        cursor: pointer;
                        text-align: center;
                        line-height:42px;
                        width: 100%;
                        border-radius:4px;
                        border:1px solid #222;
                    }
                    .addcar{
                        color: #fff;
                        background: #222;
                    }
                    .paybtn{
                        background: #fff;
                        color: #222;
                    }
                }
            }
            .wap-shopgroup{
                width: 100%;
                padding:30px 10px;
                .wap-datano{
                    width: 100%;
                    color: #95959e;
                    text-align: center;
                }
            }
        }
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        .pc-shopcar{display: none;}
        .wap-shopcar{
            padding:10px;
            .wap-title{
                font-size:18px;
                padding:0;
            }
            .wap-empty-btn{
                text-align: right;
                padding:0 10px;
                .wap-emptycar{
                    display: inline-block;
                    cursor: pointer;
                    border: 1px solid #1F73B7;
                    padding:3px 10px;
                    
                    color: #1F73B7;
                    .empcar{
                        display: inline-block;
                        width: 16px;
                        height:16px;
                        background: url(~@/assets/images/icon_delete.png) no-repeat left top;
                        background-size: 100% 100%;
                        vertical-align: middle;
                        margin-top: -4px;
                        margin-right: 4px;
                    }
                }
            }
            .wap-shop-data{
                width: 100%;
                display: flex;
                -webkit-box-orient: vertical;
                padding:10px ;
                margin: 0;
                .wap-shop-item{
                    width: 100%;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .wap-shop-img{
                        width:30%;
                        padding:0 10px 0 0;
                        img{width:100%}
                    }
                    .wap-shop-txt{
                        width:40%;
                        .title{
                            
                            padding:5px 0;
                        }
                        .txt{
                            
                            padding:0px 0 5px;
                            color: #95959e;
                        }
                        .shopnum{
                            
                            margin-top: 15px;
                        }
                    }
                    .wap-shop-shbtn{
                        width:30%;
                        text-align: right;
                        .wap-del-shopbtn{
                            text-align: right;
                            font-size:26px;
                            padding:10px 0;
                        }
                        .wap-tit_tab_btn{
                            display: inline-block;
                            border:1px solid #95959e;
                            border-radius: 8px;
                            height:32px;
                            width: 100%;
                            
                            .wap-leftbtn{
                                display: inline-block;
                                cursor: pointer;
                                height:30px;
                                line-height:30px;
                                padding:0 8px;
                                width:30%;
                                color: #a5a5ad;
                                .el-icon-minus{
                                    
                                }
                                .el-icon-plus{
                                    
                                }
                            }
                            .wap-lefttxt{
                                display: inline-block;
                                height:30px;
                                line-height:30px;
                                text-align: center;
                                width:40%;
                                vertical-align:top;
                                border-left:1px solid #95959e;
                                border-right:1px solid #95959e;
                            }
                        }
                        
                    }
                }
                
            }
            .wap-shop-btn{
                background: #fff;
                padding:0px 0px 0px;
                margin: 0;
                .wap-shop-box{
                    width: 100%;
                    background: #f4f7fe;
                    padding:10px 15px 1px;
                    .wap-shop-title{
                        padding:0;
                        
                    }
                    .wap-shop-money{
                        text-align: right;
                        padding:0;
                        font-weight: bold;
                    }
                    .money{
                        color: #bf0000;
                    }
                    .btns{
                        height:42px;
                        cursor: pointer;
                        text-align: center;
                        line-height:42px;
                        width: 100%;
                        border-radius:4px;
                        border:1px solid #222;
                    }
                    .addcar{
                        color: #fff;
                        background: #222;
                    }
                    .paybtn{
                        background: #fff;
                        color: #222;
                    }
                }
            }
            .wap-shopgroup{
                width: 100%;
                padding:30px 10px;
                .wap-datano{
                    width: 100%;
                    color: #95959e;
                    text-align: center;
                }
            }
        }
    }
}
@media screen and (max-width: 479px) {
     .wrap{
        .pc-shopcar{display: none;}
        .wap-shopcar{
            padding:10px;
            .wap-title{
                font-size:18px;
                padding:0;
            }
            .wap-empty-btn{
                text-align: right;
                padding:0 10px;
                .wap-emptycar{
                    display: inline-block;
                    cursor: pointer;
                    border: 1px solid #1F73B7;
                    padding:3px 10px;
                    
                    color: #1F73B7;
                    .empcar{
                        display: inline-block;
                        width: 16px;
                        height:16px;
                        background: url(~@/assets/images/icon_delete.png) no-repeat left top;
                        background-size: 100% 100%;
                        vertical-align: middle;
                        margin-top: -4px;
                        margin-right: 4px;
                    }
                }
            }
            .wap-shop-data{
                width: 100%;
                display: flex;
                -webkit-box-orient: vertical;
                padding:10px ;
                margin: 0;
                .wap-shop-item{
                    width: 100%;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .wap-shop-img{
                        width:30%;
                        padding:0 10px 0 0;
                        img{width:100%}
                    }
                    .wap-shop-txt{
                        width:40%;
                        .title{
                            
                            padding:5px 0;
                        }
                        .txt{
                            
                            padding:0px 0 5px;
                            color: #95959e;
                        }
                        .shopnum{
                            
                            margin-top: 15px;
                        }
                    }
                    .wap-shop-shbtn{
                        width:30%;
                        text-align: right;
                        .wap-del-shopbtn{
                            text-align: right;
                            font-size:26px;
                            padding:10px 0;
                        }
                        .wap-tit_tab_btn{
                            display: inline-block;
                            border:1px solid #95959e;
                            border-radius: 8px;
                            height:32px;
                            width: 100%;
                            
                            .wap-leftbtn{
                                display: inline-block;
                                cursor: pointer;
                                height:30px;
                                line-height:30px;
                                padding:0 8px;
                                width:30%;
                                color: #a5a5ad;
                                .el-icon-minus{
                                    
                                }
                                .el-icon-plus{
                                    
                                }
                            }
                            .wap-lefttxt{
                                display: inline-block;
                                height:30px;
                                line-height:30px;
                                text-align: center;
                                width:40%;
                                vertical-align:top;
                                border-left:1px solid #95959e;
                                border-right:1px solid #95959e;
                            }
                        }
                        
                    }
                }
                
            }
            .wap-shop-btn{
                background: #fff;
                padding:0px 0px 0px;
                margin: 0;
                .wap-shop-box{
                    width: 100%;
                    background: #f4f7fe;
                    padding:10px 15px 1px;
                    .wap-shop-title{
                        padding:0;
                        
                    }
                    .wap-shop-money{
                        text-align: right;
                        padding:0;
                        font-weight: bold;
                    }
                    .money{
                        color: #bf0000;
                    }
                    .btns{
                        height:42px;
                        cursor: pointer;
                        text-align: center;
                        line-height:42px;
                        width: 100%;
                        border-radius:4px;
                        border:1px solid #222;
                    }
                    .addcar{
                        color: #fff;
                        background: #222;
                    }
                    .paybtn{
                        background: #fff;
                        color: #222;
                    }
                }
            }
            .wap-shopgroup{
                width: 100%;
                padding:30px 10px;
                .wap-datano{
                    width: 100%;
                    color: #95959e;
                    text-align: center;
                }
            }
        }
    }
}
</style>